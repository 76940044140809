#template-list {
    z-index: 1000;
    display: none;

    .container {
        background-color: darken($color: $accent-color, $amount: 1);
        width: 500px;
        min-height: 100;
        position: absolute;
        left: 50%;
        top: 15%;
        margin-left: -250px;
        border-radius: 10px;
        border: 1px solid $accent2-color;
        box-shadow: 0px 0px 24px -3px rgba(0,0,0,0.90);
        transition: 0.3s;
        z-index: 1001;
        padding: 15px;

        &:hover {
            transform: scale(1.02);
        }

        .template-item {
            padding: 10px;
            border-radius: 10px;
            .logo {
                width: 70px;
                height: 70px;
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        opacity: 0.4;
        z-index: 1000;
    }
}