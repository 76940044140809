#dependency-page {
    position: absolute;
    left: 30px;
    top: 30px;
    bottom: 30px;
    z-index: 2000;

    pointer-events: none;
    opacity: 0;
    transition: 0.25s;
    transform: rotateX(180deg);
    perspective: 200px;
    backface-visibility: hidden;

    background-color: #0a0e15ad;
    width: 400px;
    border-radius: 20px;
    overflow: hidden;
    border: 6px solid #191d24;
    box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.2);

    &.active {
        pointer-events: all;
        opacity: 1;
        perspective: 0;
        transform: rotateX(0);
    }

    .page-title {
        background-color: #191d24;
        width: 100%;
        padding: 10px;
        padding-left: 20px;
        color: $text-hint;
        font-weight: bold;
        font-size: 18px;
        user-select: none;

        svg {
            margin-right: 10px;
        }
    }

    .container {
        height: 90%;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow: auto;
    }

    .item {
        padding: 15px;
        transition: 0.2s;
        cursor: pointer;

        &:hover {
            background-color: $accent2-color;
        }

        &:active {
            background-color: $accent3-color;
        }

        input[type="checkbox"] {
            transform: scale(1.5);
            accent-color: $accent3-color;
            filter: invert(100%) hue-rotate(18deg) brightness(1.5);
            cursor: pointer;
        }

        span {
            font-size: 18px;
            vertical-align: middle;
            font-weight: 500;
            margin-left: 20px;
            user-select: none;
            color: white;

            svg {
                margin-right: 10px;
            }
        }
    }
}