#template-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    pointer-events: none;

    &.active {
        pointer-events: all;

        .overlay {
            opacity: 0.7;
        }

        .page {
            opacity: 1;
            bottom: 0;
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $background-color;
        opacity: 0;
        z-index: 1;
        transition: 0.3s;
    }

    .page {
        position: fixed;
        bottom: -70%;
        right: 0;
        left: 0;
        background-color: $background-color;
        height: 70%;
        opacity: 0;
        z-index: 1;
        box-shadow: 0px 0px 27px 4px $background-color;
        transition: 0.3s;

        .exit-btn {
            background-color: $primary-color;
            color: white;
            border-radius: 35px;
            padding: 18px;
            padding-left: 30px;
            padding-right: 30px;
            user-select: none;
            font-weight: 500;
            cursor: pointer;
            font-size: 18px;
            transition: 0.2s;
            transform: scale(10vw);

            position: absolute;
            top: 40px;
            left: 40px;

            @media (max-width: 1200px) {
                & {
                    transform: scale(0.8);
                }
            }

            &:hover {
                background-color: white;
                color: $primary-color;

                .icon {
                    background-color: $primary-color;
                    color: white;
                }
            }

            .icon {
                transition: 0.2s;
                background-color: white;
                color: $primary-color;
                padding: 7px;
                display: inline;
                border-radius: 50px;
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }

    .container {
        position: absolute;
        left: 23%;
        right: 23%;
        top: 0;
        padding-top: 40px;
        bottom: 0;

        @media (max-width: 2500px) {
            & {
                left: 15%;
                right: 15%;
            }
        }
    }

    .heading-title {
        font-size: 40px;
        color: white;
        font-weight: 550;
        user-select: none;

        span {
            color: gray;
            font-size: 30px;
            font-weight: 400;
            margin-left: 10px;
        }
    }

    .tabs {
        margin-top: 50px;
        border-bottom: 2px solid $primary-color;
        height: 72px;

        .tab-item {
            display: inline-block;
            font-size: 23px;
            margin-right: 45px;
            cursor: pointer;
            user-select: none;
            transition: 0.15s;
            padding-top: 20px;
            padding-bottom: 20px;

            span {
                font-size: 23px;
                font-weight: 500;
                margin-left: 10px;
            }

            &:hover {
                color: white;
            }

            &.active {
                color: white;
                border-bottom: 3px solid $accent3-color;
            }
        }
    }

    .tab-content {
        padding-top: 40px;
        height: 70%;
        text-align: center;
        overflow: auto;
    }

    .template-item {
        position: relative;
        vertical-align: top;
        background-color: #0e1419;
        border: 2px solid $primary-color;
        width: 25%;
        height: 420px;
        border-radius: 25px;
        display: inline-block;
        margin-left: 1%;
        margin-right: 1%;
        text-align: left;
        margin-bottom: 3%;
        padding: 1.5vw;
        transition: 0.3s;

        &:hover {
            transform: scale(1.03);
        }

        .integration-icon {
            $size: 40px;
            width: $size;
            height: $size;
            background-color: black;
            border-radius: 10px;
            display: inline-block;
            padding: 8px;
            margin-right: 10px;

            .integration-icon-chatgpt {
                width: 100%;
                height: 100%;
                background-image: url("/assets/icons/chatgpt.png");
                background-size: contain;
                background-repeat: no-repeat;
            }

            &.chatgpt {
                background-color: white;
            }

            .integration-icon-uniswap {
                width: 100%;
                height: 100%;
                background-image: url("/assets/icons/integration/uniswap.png");
                background-size: contain;
                background-repeat: no-repeat;
            }

            &.uniswap {
                background-color: #fb077b;
            }

            .integration-icon-telegram {
                width: 100%;
                height: 100%;
                background-image: url("/assets/icons/integration/telegram.webp");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.telegram {
                background-color: #3ca6e0;
            }

            .integration-icon-dextools {
                width: 100%;
                height: 100%;
                background-image: url("/assets/icons/integration/dextools.png");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.dextools {
                background-color: white;
            }

            .integration-icon-lua {
                width: 100%;
                height: 100%;
                background-image: url("/assets/icons/integration/lua.png");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.lua {
                background-color: #000081;
            }

            .integration-icon-graphlinq {
                width: 100%;
                height: 100%;
                background-image: url("/assets/icons/integration/graphlinq.png");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.graphlinq {
                background-color: #8a42e5;
            }

            .integration-icon-ethereum {
                width: 100%;
                height: 100%;
                background-image: url("/assets/icons/integration/ethereum.png");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.ethereum {
                background-color: #222122;
            }

            .integration-icon-web {
                width: 100%;
                height: 100%;
                background-image: url("/assets/icons/integration/web.png");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                filter: invert(1);
            }

            &.web {
                background-color: #2f8b74;
            }
        }

        .template-title {
            font-size: 30px;
            color: white;
            font-weight: 500;
            margin-top: 30px;
        }

        .template-desc {
            font-size: 22px;
            color: $text-color;
            font-weight: 300;
            margin-top: 40px;

            @media (max-width: 2000px) {
                & {
                    font-size: 18px;
                }
            }
        }

        .template-use-btn {
            position: absolute;
            bottom: 1.5vw;
            border: 2px solid $accent3-color;
            padding: 15px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 40px;
            color: white;
            font-size: 18px;
            user-select: none;
            font-weight: 600;
            cursor: pointer;

            svg {
                margin-right: 10px;
            }

            &:hover {
                border: 2px solid transparent;
                background: linear-gradient(0deg, rgba(44, 102, 206, 1) 5%, rgba(99, 200, 250, 1) 95%);
            }
        }
    }
}