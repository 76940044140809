@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-Medium.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-SemiBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-HeavyItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-UltraLightItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-Thin.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-Regular.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-Bold.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-Heavy.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-Black.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-UltraLight.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-Light.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: '/assets/fonts/Gilroy-RegularItalic';
    src: url('/assets/fonts/Gilroy-RegularItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy-ThinItalic.woff2') format('woff2'),
        url('/assets/fonts/Gilroy-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

