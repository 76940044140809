@keyframes appearIn {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        opacity: 0.9;
        transform: scale(1);
    }
}

@keyframes consoleEntryAnimation {
    from {
        opacity: 0.5;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes appearInToolbox {
    from {
        transform: translateX(-300px);
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes appearInConsole {
    from {
        transform: translateY(300px);
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes appearInGraph {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes appearInMenu {
    from {
        transform: translateY(-300px);
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
  }
  

@keyframes disappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}
