#toolbox {
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    width: 300px;
    height: bottom;
    margin-top: 1px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
    animation-name: appearInToolbox;
    animation-duration: 1s;
    padding: 10px;

    #search-manager {
        background-color: $accent-color;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;

        .title {
            margin-top: 3px;
            margin-bottom: 3px;
            padding: 10px;
            padding-left: 15px;
            color: $text-hint;
            font-weight: bold;
            font-size: 15px;
            background-color: #191d24;
            svg {
                margin-right: 3px;
            }
        }
    }

    #node-manager {
        background-color: $accent-color;
        border-radius: 10px;
        overflow: hidden;
        position: absolute;
        bottom: 20;
        top: 490px;
        left: 10;
        right: 10;

        .title {
            margin-top: 3px;
            margin-bottom: 3px;
            padding: 10px;
            padding-left: 15px;
            color: $text-hint;
            font-weight: bold;
            font-size: 15px;
            background-color: #191d24;
            svg {
                margin-right: 3px;
            }
        }

        .group-container {
            overflow: auto;
            height: 100%
        }
    }

    #project-manager {
        background-color: $accent-color;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;

        .title {
            margin-top: 3px;
            margin-bottom: 3px;
            padding: 10px;
            padding-left: 15px;
            color: $text-hint;
            font-weight: bold;
            font-size: 15px;
            background-color: #191d24;
            svg {
                margin-right: 3px;
            }
        }

        .projects-container {
            overflow: auto;
            height: 300px;
        }

        .project-item{
            font-family: "Gilroy", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
            padding: 12px;
            padding-left: 15px;
            padding-right: 15px;
            cursor: pointer;
            transition: 0.2s;
            position: relative;
            color: white;
            position: relative;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            font-weight: 600;
            
            animation-name: appearInToolbox;
            animation-duration: 1s;
            border-bottom: 1px solid #252b36;

            svg {
                margin-right: 6px;
                font-size: 11px;
            }

            span {
                width: 500px;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                font-size: 14px;
                svg {
                    color: $text-color;
                }
            }

            .delete-item {
                opacity: 0;
                vertical-align: middle;
                transition: 0.5s;
                position: absolute;
                top: 0px;
                bottom: 0px;
                right: 0px;
                width: 35px;
                color: white;
                padding-top: 15px;
                text-align: center;
                transform: translateX(50px);
                font-size: 12px;
            }

            &:hover {
                background-color: darken($primary-color, 3);
                color: white;

                .delete-item {
                    opacity: 1;
                    background-color: rgba(172, 37, 37, 0.7);
                    transform: translateX(0px);

                    &:hover {
                        background-color: rgb(172, 37, 37);
                    }
                }
            }

            &:active {
                background-color: lighten($color: $accent-color, $amount: 8);
            }
        }
    }

    .col-exp-all {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        cursor: pointer;
    }

    .searchbox {
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;

        input[type="text"] {
            width: 100%;
            padding: 8px;
            border: none;
            background-color: transparent;
            color: $text-color;
            border-radius: 10px;
            text-align: center;
            transition: 0.2s;
            font-size: 16px;
            outline-width: 0;

            &:focus {
                color: white;
                outline: none;
            }

            &::placeholder {
                color: $text-color;
            }
        }
    }

    .group-item {
        user-select: none;
        font-size: 15px;
        color: white;
        border-bottom: 1px solid #252b36;
        padding-bottom: 10px;

        .group-title {
            //background-color: darken($color: $accent-color, $amount: 2);
            position: relative;
            cursor: pointer;
            padding: 15px 9px;
            font-weight: 600;

            .expand {
                position: absolute;
                right: 10px;
                top: 18px;
            }

            svg:nth-child(1) {
                margin-right: 10px;
            }
        }

        .group-node-item{
            padding: 5px 5px;
            cursor: pointer;
            transition: 0.2s;
            position: relative;
            font-size: 13px;
            color: $text-color;
            padding-left: 40px;

            &:hover {
                background-color: darken($primary-color, 2);
                padding-left: 50px;
                color: white;
            }

            &:active {
                background-color: lighten($color: $accent-color, $amount: 8);
            }

            .node-type {
                background-color: red;
                position: absolute;
                right: 8px;
                top: 10px;
                width: 15px;
                height: 6px;
                border-radius: 7px;

                &.connector {
                    background-color: $node-type-connector-color;
                }

                &.event {
                    background-color: $node-type-event-color;
                }

                &.function {
                    background-color: $node-type-function-color;
                }

                &.variable {
                    background-color: $node-type-variable-color;
                }

                &.condition {
                    background-color: $node-type-condition-color;
                }

                &.entry-point {
                    background-color: $node-type-entry-point-color;
                }

                &.deployer {
                    background-color: $node-type-deployer-color;
                }
            }

            .node-description {
                display: none;
            }
        }
    }
}