#graph-container {
    position: fixed;
    left: 330px;
    bottom: 350px;
    right: 16px;
    top: 60px;
    border-radius: 20px;
    transition: 0.3s;
    transition-property: box-shadow;
    background-image: radial-gradient(#1a1d24 3px, transparent 0);
    background-size: 2em 2em;
    animation-name: appearInGraph;
    animation-duration: 3s;
    overflow: hidden;
    background-color: $primary-color;

    &.maximized {
        bottom: 20px;
    }

    .inner-container {
        width: 10000%;
        height: 10000%;
        position: absolute;
        top: 0;
        left: 0;

        .node-comment-group {
            position: absolute;
            top: 50px;
            left: 50px;
            animation-name: appearIn;
            animation-duration: 0.3s;
            user-select: none;
            border: 6px solid #0a0e15b2;
            background-color: #2d344144;
            z-index: 0;
            min-width: 700px;
            min-height: 300px;

            .color-container {
                position: absolute;
                right: 55px;
                top: 10px;
                font-size: 25px;

                .color-item {
                    display: inline-block;
                    background-color: white;
                    width: 15px;
                    height: 15px;
                    border-radius: 5px;
                    margin-left: 2px;
                    cursor: pointer;
                    transition: 0.3s;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            .embed-content {
                padding: 10px;
                position: absolute;
                top: 90px;
                bottom: 0;
                right: 0;
                left: 0;
                
                iframe {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }

            .node-comment-group-title {
                width: 100%;
                font-size: 19px;
                font-weight: 500;
                align-items: center;
                background-color: #0a0e158c;
                //background-color: red;
                padding-top: 15px;
                padding-bottom: 15px;
                cursor: move;
                transition: 0.2s;
                border-bottom: 2px solid #0a0e15b2;

                input {
                    transition: 0.2s;
                    margin-left: 10px;
                    font-size: 19px;
                    border: none;
                    background-color: transparent;
                    color: white;
                    width: 70%;

                    &:focus {
                        outline: none;
                    }
                }

                svg {
                    transition: 0.2s;
                    margin-left: 20px;
                }
            }

            .delete-group-btn {
                position: absolute;
                right: 15px;
                top: 14px;
                font-size: 25px;
                cursor: pointer;

                &:hover {
                    color: red;
                }
            }

            .node-comment-group-description {
                width: 100%;
                font-size: 15px;
                font-weight: 500;
                background-color: #252e3d8c;
                padding-top: 7px;
                padding-bottom: 7px;
                border-bottom: 2px solid #0a0e15b2;

                &:hover {
                    input {
                        color: white;
                    }
                }

                input {
                    transition: 0.2s;
                    padding-left: 15px;
                    padding-right: 15px;
                    font-size: 16px;
                    border: none;
                    background-color: transparent;
                    color: $text-color;
                    width: 100%;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .resize-handle {
                position: absolute;
                right: -15px;
                bottom: -15px;
                width: 30px;
                height: 30px;
                cursor: se-resize;
            }
        }

        .node-comment {
            display: inline;
            position: absolute;
            top: 50px;
            left: 50px;
            user-select: none;
            opacity: 0.7;
            animation-name: appearIn;
            animation-duration: 0.3s;
            transition: background-color 0.3s;
            border-radius: 15px;
            z-index: 1;

            &:hover {
                background-color: $background-color;
                cursor: move;

                .comment-toolbar .comment-toolbar-icon {
                    opacity: 1;
                }
            }

            .text-comment {
                padding: 15px;
                font-size: 25px;

                input {
                    margin: 5px;
                    padding: 5px;
                    font-size: 23px;
                    background: none;
                    border: none;
                    color: $text-color;
                    width: auto;
                    text-align: center;
                    width: 250px;

                    &:focus {
                        outline-width: 0;
                    }

                    &::placeholder {
                        color: $text-color;
                    }
                }
            }

            .comment-toolbar {
                position: absolute;
                top: 5px;
                right: 5px;
                min-height: 20px;

                .comment-toolbar-icon {
                    cursor: pointer;
                    padding: 5px;
                    transition: 0.3s;
                    opacity: 0;

                    &:hover {
                        color: white;
                        //background-color: darken($accent-color, 14);
                    }
                }
            }
        }

        .node {
            min-width: 150px;
            min-height: 50px;
            background-color: lighten($color: $primary-color, $amount: 6);
            display: inline;
            border: 1px solid $accent2-color;
            box-shadow: 0px 0px 24px -10px rgba(0, 0, 0, 0.75);
            position: absolute;
            top: 50px;
            left: 50px;
            user-select: none;
            opacity: 0.95;
            animation-name: appearIn;
            animation-duration: 0.3s;
            border-radius: 10px;
            transition: transform 0.25s, box-shadow 0.25s;
            transform: scale(1);
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
            padding-bottom: 5px;
            z-index: 1;

            &:hover {
                //transform: scale(1.03);
                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 1);

            }

            .none-toolbar {
                position: relative;
                background-color: $accent-color;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                .node-type {
                    background-color: red;
                    width: 15px;
                    height: 7px;
                    border-radius: 7px;
                    margin-right: 7px;

                    &.connector {
                        background-color: $node-type-connector-color;
                    }

                    &.event {
                        background-color: $node-type-event-color;
                    }

                    &.function {
                        background-color: $node-type-function-color;
                    }

                    &.variable {
                        background-color: $node-type-variable-color;
                    }

                    &.condition {
                        background-color: $node-type-condition-color;
                    }

                    &.entry-point {
                        background-color: $node-type-entry-point-color;
                    }

                    &.deployer {
                        background-color: $node-type-deployer-color;
                    }
                }

                .node-title {
                    font-size: 13px;
                    color: white;
                    text-align: center;
                    font-weight: 550;
                    cursor: move;
                    display: flex;
                    align-items: center;
                    flex: 5;
                    height: 30px;
                    vertical-align: center;
                    padding-right: 12px;
                    padding-left: 12px;
                    white-space: nowrap;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;

                    &.connector {
                        background: rgba(130, 236, 236, 0.3);
                    }

                    &.deployer {
                        background: rgba(202, 94, 43, 0.3);
                    }

                    &.variable {
                        background: #46dc824f;
                    }

                    &.function {
                        background: rgba(96, 106, 199, 0.3);
                    }

                    &.event {
                        background: rgba(209, 169, 58, 0.3);
                    }

                    &.condition {
                        background: rgba(226, 123, 230, 0.3);
                    }

                    &.entry-point {
                        background: rgba(209, 169, 58, 0.3);
                    }
                }

                .node-toolbar-icon {
                    text-align: center;
                    height: 25px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: 0.2s;
                    opacity: 0;
                    margin-left: 5px;
                    position: absolute;
                    top: 8;
                    bottom: 0;
                    right: 10;

                    &:hover {
                        color: white;
                    }
                }

                &:hover {
                    .node-toolbar-icon {
                        opacity: 1;
                    }
                }

                .node-toolbar-custom-icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 7px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                    &.custom-icon-dextools {
                        background-image: url("/assets/icons/dextools.png");
                    }

                    &.custom-icon-solana {
                        background-image: url("/assets/icons/solana.png");
                    }

                    &.custom-icon-binance {
                        background-image: url("/assets/icons/binance.png");
                    }

                    &.custom-icon-kucoin {
                        background-image: url("/assets/icons/kucoin.png");
                    }

                    &.custom-icon-lua {
                        background-image: url("/assets/icons/lua.png");
                    }

                    &.custom-icon-timer {
                        background-image: url("/assets/icons/timer.png");
                    }

                    &.custom-icon-string {
                        background-image: url("/assets/icons/string.png");
                    }

                    &.custom-icon-integer {
                        background-image: url("/assets/icons/integer.png");
                    }

                    &.custom-icon-boolean {
                        background-image: url("/assets/icons/boolean.png");
                    }

                    &.custom-icon-print {
                        background-image: url("/assets/icons/print.png");
                    }

                    &.custom-icon-telegram {
                        background-image: url("/assets/icons/telegram.webp");
                    }
                }
            }

            .node-parameters {
                display: flex;
                padding-top: 5px;
                padding-bottom: 2px;

                .node-parameter-container {
                    flex: 1;

                    .node-parameter {
                        min-height: 20px;
                        font-size: 13px;
                        position: relative;
                        margin-top: 3px;
                        margin-bottom: 3px;
                        transition: color 0.2s, min-height 0.2s;
                        text-transform: capitalize;
                        white-space: nowrap;

                        &:hover {
                            color: white;
                        }

                        .dot {
                            $size: 10px;
                            height: $size;
                            width: $size;
                            border-radius: 50px;
                            background-color: white;
                            position: absolute;
                            top: 5px;
                            cursor: pointer;
                            transition: 0.2s;

                            &:hover {
                                transform: scale(1.3);
                                animation: pulsate 1s infinite;
                            }

                            &.reference {
                                background-color: $parameter-type-execution-color;
                            }
                        }

                        &.connector {
                            .dot {
                                background-color: $parameter-type-connector-color;
                            }
                        }

                        &.system-string {
                            .dot {
                                background-color: $parameter-type-string-color;
                            }
                        }

                        &.system-int32 {
                            .dot {
                                background-color: $parameter-type-int32-color;
                            }
                        }

                        &.system-int64 {
                            .dot {
                                background-color: $parameter-type-int64-color;
                            }
                        }

                        &.system-uint64 {
                            .dot {
                                background-color: $parameter-type-int64-color;
                            }
                        }

                        &.system-double {
                            .dot {
                                background-color: $parameter-type-double-color;
                            }
                        }

                        &.system-object {
                            .dot {
                                background-color: $parameter-type-object-color;
                            }
                        }

                        &.system-boolean {
                            .dot {
                                background-color: $parameter-type-boolean-color;
                            }
                        }

                        &.system-bytebb {
                            .dot {
                                background-color: $parameter-type-bytebb-color;
                            }
                        }

                        &.nodeblock-plugin-ethereum-nodes-eth-coincreator-models-erc20creatormodel {
                            .dot {
                                background-color: $node-type-deployer-color;
                            }
                        }

                        &.execution {
                            .dot {
                                background-color: $parameter-type-execution-color;
                            }
                        }

                        .parameter-value-input {
                            background-color: #151920;
                            height: 23px;
                            margin-top: 8px;
                            width: 100%;
                            border: none;
                            border-radius: 5px;
                            color: white;
                            text-align: center;

                            &:focus {
                                outline-width: 0;
                            }

                            &::placeholder {
                                color: $text-color;
                            }

                            &.textarea {
                                width: 130px;
                                height: 37px;
                                text-align: left;
                                resize: none;
                                padding: 7px;
                                font-family: Consolas, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                            }
                        }

                        .parameter-value-checkbox {
                            margin-top: 15px;
                            margin-bottom: 10px;
                            transform: scale(1.5);
                            accent-color: $accent3-color;
                            filter: invert(100%) hue-rotate(18deg) brightness(1.5);
                        }

                        @keyframes pulsate {
                            0% {
                                transform: scale(1);
                                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
                            }

                            70% {
                                transform: scale(1.2);
                                box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
                            }

                            100% {
                                transform: scale(1);
                                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
                            }
                        }

                        .script-editor {
                            text-transform: none;
                            width: 650px;
                            min-height: 200px;
                            margin-left: 10px;
                            margin-top: 7px;
                            margin-bottom: 10px;
                            border-radius: 8px;
                            border: 1px solid $accent-color;
                            
                            &[data-collapsed="true"] {
                                min-height: 0;
                                transform: scaleY(0);
                            }
                        }
                    }

                    &.in {
                        .node-parameter {
                            padding-left: 10px;
                            padding-right: 10px;

                            .dot {
                                left: -6px;
                            }
                        }
                    }

                    &.out {
                        .node-parameter {
                            text-align: right;
                            padding-right: 10px;

                            .dot {
                                right: -6px;
                            }
                        }
                    }
                }
            }
        }

        .node-graph-line {
            width: 100%;
            height: 100%;
            z-index: -1;
            pointer-events: none;
            position: absolute;
            background: transparent;
            fill: transparent;
            animation: dash 80s linear;
            animation-iteration-count: infinite;
        }
    }

    #linker-block {
        position: absolute;
        bottom: 30px;
        left: 30px;
        background-color: darken($primary-color, 8);
        height: 40px;
        border-radius: 7px;
        pointer-events: initial;

        &.disable {
            opacity: 0;
            pointer-events: none;
        }

        .btn {
            line-height: 40px;
            padding-left: 10px;
            padding-right: 10px;
            transition: 0.2s;
            cursor: pointer;
            border-radius: 7px;
            user-select: none;

            &.red {
                &:hover {
                    background-color: rgb(180, 48, 48);
                }

                &:active {
                    background-color: rgb(170, 34, 34);
                }
            }
        }
    }
}

#subgraph-toolbox {
    display: flex;
    position: absolute;
    bottom: 280px;
    left: 330px;
    right: 20px;
    height: 50px;
    animation-name: appearIn;
    animation-duration: 1s;

    &.maximized {
        bottom: 40px;
        left: 350px;

        #minimine-terminal-btn {
            display: none;
        }
        #maximize-terminal-btn {
            display: block;
        }
    }

    #maximize-terminal-btn {
        display: none;
    }

    .btn {
        background-color: $primary-color;
        margin-right: 15px;
        text-align: center;
        border-radius: 5px;
        vertical-align: middle;
        padding-top: 13px;
        padding-bottom: 13px;
        padding-left: 20px;
        padding-right: 20px;
        cursor: pointer;
        transition: 0.2s;
        font-size: 21px;
        user-select: none;

        span {
            margin-left: 7px;
            font-weight: 500;
        }

        &:hover {
            color: $primary-color;
            background-color: $accent3-color;
        }
    }
}

#ai-prompt {
    flex: 1;
    border-radius: 5px;
    opacity: 0.8;
    background-color: $primary-color;

    input[type="text"] {
        background: transparent;
        border: none;
        color: white;
        width: 90%;
        height: 100%;
        padding-left: 20px;
        margin-left: 45px;
        font-size: 20px;
        display: inline-block;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

        &:focus {
            outline: none;
        }
    }

    svg {
        margin-left: 20px;
        display: block;
        font-size: 23px;
        position: absolute;
        top: 13px;
    }

    .bouncing-dots {
        position: absolute;
        left: 70px;
        top: 18px;
        width: 150px;
        display: block;
        justify-content: center;
        align-items: center;
    }

    .dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 5px;
        animation: bounce 0.6s infinite alternate;
        background: linear-gradient(to right, rgb(144, 196, 255), rgb(30, 184, 255));
        box-shadow: 0px 0px 5px rgb(129, 194, 255);
    }

    @keyframes bounce {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(-10px);
        }
    }

    #dot2 {
        animation-delay: 0.2s;
    }

    #dot3 {
        animation-delay: 0.4s;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }


    @-webkit-keyframes rotate {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
        }
    }

    .load {
        position: absolute;
        width: 45px;
        height: 45px;
        left: 10px;
        border: solid 3px #227faa;
        border-radius: 50%;
        border-right-color: transparent;
        border-bottom-color: transparent;
        -webkit-transition: all 0.5s ease-in;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 1.0s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;

        transition: all 0.5s ease-in;
        animation-name: rotate;
        animation-duration: 1.0s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}