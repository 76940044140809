#minimap {
    position: absolute;
    bottom: 35px;
    right: 10px;
    width: 250px;
    height: 140px;
    background-color: #0e131b8c;
    border-radius: 10px;
    opacity: 1;
    transition: 2s;
    transform:
        scale(0.8)
        rotateX(51deg)
        rotateZ(43deg);
    transform-style: preserve-3d;
    box-shadow:
        1px 1px 0 1px #0e131b8c,
        -1px 0 28px 0 rgba(0, 0, 0, 0.01),
        28px 28px 28px 0 rgba(0, 0, 0, 0.25);
    transition:
        .4s ease-in-out transform,
        .4s ease-in-out box-shadow;

    &:hover {
        transform: none;
        transform-style: preserve-3d;
        box-shadow: none;
        transition:
            .4s ease-in-out transform,
            .4s ease-in-out box-shadow;
    }

    canvas {
        width: 100%;
        height: 100%;
    }
}