.btn-classic {
    border-radius: 3px;
    color: $text-color;
    font-size: 11px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;
    background-color: $primary-color;
    padding: 5px;
    margin: 5px;

    &:hover {
        color: white;
        background-color: darken($color: $primary-color, $amount: 3);
    }
}