#menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 37px;
    background-color: $accent-color;
    border-bottom: 1px solid $accent2-color;
    z-index: 1000;
    user-select: none;

    animation-name: appearInMenu;
    animation-duration: 1s;

    .logo {
        background-image: url(/assets/logo_2.png);
        background-repeat: no-repeat;
        background-size: contain;
        height: 20px;
        margin-left: 15px;
        margin-right: 15px;
        width: 150px;
        display: inline-block;
        vertical-align: middle;

        animation-name: appearInToolbox;
        animation-duration: 2.5s;
    }

    .right-container {
        position: absolute;
        right: 0;
        top: 0;
    }

    .menu-item {
        color: $text-color;
        display: inline-block;
        padding: 0px 10px;
        height: 37px;
        line-height: 37px;
        text-align: center;
        vertical-align: middle;
        font-size: 12px;
        transition: 0.1s;
        cursor: pointer;
        letter-spacing: 1px;
        position: relative;
        animation-name: appearInMenu;
        animation-duration: 2s;
        font-weight: 600;

        svg {
            margin-right: 3px;
        }

        &:hover {
            color: $primary-color;
            background-color: $accent3-color;

            .sub-menu {
                pointer-events: initial;
                opacity: 1;
                transform: translateY(0px);
            }
        }

        .sub-menu {
            color: $text-color;
            background-color: $accent-color;
            border: 1px solid $accent2-color;
            position: absolute;
            top: 36px;
            left: 0px;
            transition: 0.2s;
            pointer-events: none;
            opacity: 0;
            text-align: left;
            box-shadow: 0px 0px 24px -3px rgba(0,0,0,0.90);
            transform: translateY(-10px);
            font-weight: 500;

            .sub-menu-item {
                display: block;
                padding: 0px 10px;
                transition: 0.3s;
                min-width: 150px;

                &:hover {
                    background-color: $accent3-color;
                    color: $primary-color;
                }
            }
        }
    }
}