@import "fonts.scss";
@import "colors.scss";
@import "animations.scss";
@import "menu.scss";
@import "buttons.scss";
@import "console.scss";
@import "toolbox.scss";
@import "graph.scss";
@import "toolbar.scss";
@import "template-list.scss";
@import "minimap.scss";
@import "node-help.scss";
@import "subgraph.scss";
@import "template_page.scss";
@import "dependency_page.scss";

body,
html {
    margin: 0;
    padding: 0;
    background-color: $background-color;
    color: $text-color;
    font-family: "Gilroy", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: none;
}

::-webkit-scrollbar-thumb {
    background: lighten($primary-color, 3);
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: lighten($primary-color, 6);
}

.tooltip {
    height: 20px;
    font-size: 13px;
    color: $text-color;
    text-align: start;
    box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
    background-color: lighten($accent-color, 3);
    border: 1px solid $accent2-color;
    padding: 4px;
    position: absolute;
    bottom: 0px;
    left: 250px;
    opacity: 0.9;
    z-index: 1000;
    transition: 0.2s;
    pointer-events: none;
}

#loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    pointer-events: none;
    z-index: 100000;
    background-color: $background-color; 
    display: flex;
    justify-content: center;
    align-items: center;
    animation: disappear 1s ease forwards;
    animation-delay: 1.7s; 
    opacity: 1;
    
    .logo {
        width: 13%;
        animation: disappear 0.5s ease forwards;
        animation-delay: 0.9s; 
    }
}
