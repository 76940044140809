#toolbar {
    position: fixed;
    right: 40px;
    top: 80px;
    background-color: rgb(27, 33, 43);
    z-index: 999;
    animation-name: appearIn;
    animation-duration: 1s;
    user-select: none;
    box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.2);
    border-radius: 10px;
    overflow: hidden;

    .toolbar-btn {
        padding: 12px;
        transition: 0.3s;
        display: inline-block;
        cursor: pointer;

        &:hover {
            background-color: darken($primary-color, 12);
        }

        &:active {
            background-color: darken($primary-color, 15);
            color: white;
        }
    }

    .toolbar-input {
        input {
            padding: 8px;
            font-size: 16px;
            background-color: rgb(19, 23, 31);
            border: none;
            color: $text-color;
            text-align: center;

            &:focus {
                outline-width: 0;
            }

            &::placeholder {
                color: darken($text-color, 10);
            }
        }
    }
}