#console {
    position: fixed;
    bottom: 20px;
    left: 330px;
    right: 20px;
    height: 240px;
    margin-left: 1px;
    padding-top: 5px;
    padding-left: 3px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.45s;
    
    animation-name: appearInConsole;
    animation-duration: 1s;

    &.minimized {
        height: 0;
        opacity: 0;
    }

    .header {
        position: absolute;
        right: 20px;
        top: 10px;
        opacity: 0.6;
        pointer-events: none;
    }

    .console-entry-container {
        flex: 1;
        padding: 2px;
    }

    .logs-entry-container {
        flex: 1;
        padding: 2px;
    }

    .console-entry-scroll {
        background-color: $accent-color;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;

        .title {
            margin-top: 3px;
            margin-bottom: 3px;
            padding: 10px;
            padding-left: 15px;
            color: $text-hint;
            font-weight: bold;
            font-size: 15px;
            background-color: #191d24;
            svg {
                margin-right: 3px;
            }
        }
        
        .console-entry-container {
            overflow: auto;
            height: 190px;
        }

        .console-input {
            display: none;
            input {
                background-color: transparent;
                width: 100%;
                border: none;
                font-size: 18px;
                padding-left: 20px;
                padding-right: 20px;
                color: white;

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .logs-entry-scroll {
        background-color: $accent-color;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;

        .title {
            margin-top: 3px;
            margin-bottom: 3px;
            padding: 10px;
            padding-left: 15px;
            color: $text-hint;
            font-weight: bold;
            font-size: 15px;
            background-color: #191d24;
            svg {
                margin-right: 3px;
            }
        }
    }

    .console-entry {
        margin: 2px;
        padding: 4px;
        color: $text-color;
        font-family: "Gilroy";
        font-size: 15px;
        //animation-name: consoleEntryAnimation;
        //animation-duration: 0.3s;

        &:hover {
            background-color: lighten($color: $accent-color, $amount: 3);            
        }

        &.success {
            color: #46dc82;
        }

        &.warning {
            color: yellow;
        }

        &.error {
            color: rgb(255, 81, 81);
        }

        &.ai {
            color: rgb(0, 132, 255);
        }

        a {
            color: $accent3-color;
            letter-spacing: 0.4px;
            font-weight: bold;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}